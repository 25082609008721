.article {
	position: relative;
	width: 100%;
	max-width: 100%;
	height: 100%;
	overflow-x: hidden;
	box-sizing: border-box;
}

.article ul {
	display: block;
	list-style-type: disc;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 40px;
}

.article ul,
.article ul li {
	width: 100%;
}

.article button:disabled {
	opacity: 0.5;
	cursor: not-allowed !important;
}

.article figure {
	margin: 0;
	margin-block-start: 0em;
	margin-block-end: 0em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

.canvas {
	display: block;
	height: 100%;
	max-width: 1024px;
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
}

.canvas.ad {
	overflow: hidden;
	height: 100%;
}

.canvas.ad > div {
	height: 100%;
}

.image {
	color: #000;
	display: flex;
	flex-direction: column;
}

.image > img,
.image > a > img {
	height: auto;
	max-width: 100%;
	object-fit: cover;
	text-align: center;
}

.image > figcaption {
	text-align: center;
	width: 100%;
}

.image > cite {
	display: block;
	text-align: center;
}

.infogram {
	display: block;
}

.map {
	width: 100%;
	height: 45vh;
	overflow: hidden;
}

.map > :first-child {
	height: 100%;
	width: 100%;
}

.gallery {
	height: auto;
}

.gallery > figcaption {
	margin-top: 5px;
	text-align: center;
}

.gallery-slider {
	margin: auto 0px;
}

.gallery-slider.hide > .gallery-image {
	opacity: 0;
}

.gallery-image {
	width: 100%;
	max-width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.gallery-image img {
	object-fit: cover;
	width: 100%;
	max-width: 100% !important;
}

.gallery-image figcaption {
	margin-bottom: 20px;
}

/*.video:not(.auto) {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
	max-width: 100%;
}*/
.video iframe,
.video object,
.video embed,
.video video {
	top: 0;
	left: 0;
}

.video .brightcove {
	width: 100%;
	height: 496px;
}

.video video {
	max-width: 100%;
	margin: 0 auto;
	display: block;
}

.video.auto {
	padding-bottom: unset;
	height: unset;
	overflow: unset;
}

.video.auto iframe,
.video.auto object,
.video.auto embed,
.video.auto video {
	position: unset;
	top: unset;
	left: unset;
	width: unset;
	height: unset;
}

.anchor {
	width: 0;
	height: 0;
	opacity: 0;
}

.video {
	display: block;
	position: relative;
}

.video > figcaption {
	text-align: left;
	padding: 2% 20px;
	color: #000;
}

.video > cite {
	text-align: left;
	padding: 0px 20px;
	color: #000;
}

.twitter {
	overflow: auto;
}

.twitter > div {
	width: 100%;
	height: 100%;
}

.twitter > div > div {
	width: 100%;
	height: 100%;
}

.columns {
	display: grid;
	grid-template-rows: 1fr;
	grid-column-gap: 15px;
	margin: 0px;
	max-width: 100%;
	padding: 0px;
	background-size: cover !important;
}

.columns > div {
	width: 100%;
}

.columns .gallery-slider {
	width: 100% !important;
}

.columns > div > figure.float-left {
	float: left;
	margin-bottom: 0px !important;
}

.columns > div > figure.float-right {
	float: right;
	margin-bottom: 0px !important;
}

.columns > div > * {
	max-width: 100% !important;
}

.column-1 {
	display: grid;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '.';
}

.column-2 {
	grid-template-areas: '. .';
}

.column-3 {
	grid-template-areas: '. . .';
}

.column-4 {
	grid-template-areas: '. . . .';
}

.collapse {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
}

.advert {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.advert img {
	object-fit: contain;
	min-height: 100%;
	overflow: hidden;
}

.tabs {
	padding: 0px;
}

.tabs > div {
	display: flex;
	flex-direction: column;
}

.tabs .tab {
	height: 100%;
	min-width: 100px;
	background: white;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 100;
	border: 1px solid transparent;
}

.tab.selected {
	cursor: unset;
	border-color: #eaeaea;
}

.tabs nav {
	min-height: unset;
	display: flex;
}

.tabs nav > div {
	padding: 5px 25px;
}

.tabs .content {
	min-height: 100px;
}

/*.multicol .component p,
.multicol figure {
	page-break-inside: avoid;
	break-inside: avoid-column;
	display: inline-table;
	overflow: hidden;
}*/

.multicol figure {
	display: contents;
}

.multicol .component p {
}

.instagram {
	position: relative;
	display: flex;
	justify-content: center;
}

@media screen and (max-width: 767px) {
	.advert {
		min-height: 100%;
		max-height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media screen and (min-width: 767px) {
	.advert {
		min-height: 100%;
		max-height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media print {
	.canvas {
		display: block;
		overflow-y: visible;
	}
}
